//tslint:disable
//ts-ignore
//ts-nocheck

import {useState, useEffect, useContext} from 'react'
import classNames from 'classnames'
import {toast} from 'react-toastify'
import {formatDate, toAbsoluteUrl} from 'helpers'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {useTranslation, Trans} from 'react-i18next'
//STYLES
import styles from './CampaignDetail.module.scss'
// COMPONENTS
import CampaignDemo from './components/CampaignDemo/CampaignDemo'
import PollDemo from './components/PollDemo/PollDemo'
import {Campaign} from 'components/CampaignPopup/CampaignPopup'
import TargetingInfo from './components/TargetingInfo/TargetingInfo'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import CampaignContext from 'context/CampaignContext'
// TYPES
import {GetAutoAcceptSettingsResponse} from 'services/model/response/streamers/getAutoAcceptSettingsResponse'
import EnumStreamerAutoAcceptPreference from 'services/model/enum/enumStreamerAutoAcceptPreference'
import {createCampaignService} from 'services/campaignService'
import EnumCampaignHasStreamerStatus from 'services/model/enum/enumCampaignHasStreamerStatus'

export type CampaignDetailProps = {
  campaign: Campaign | null
  streamerList: {
    streamers:
      | [
          {
            link_to_logo: string | null
            title: string
          }
        ]
      | null
    count: number
  }
  autoAcceptSettings: GetAutoAcceptSettingsResponse | null
}

const CampaignDetail = ({campaign, streamerList, autoAcceptSettings}: CampaignDetailProps) => {
  const campaignService = createCampaignService()
  const {user} = useContext(AppLevelContext)
  const {setActiveFilter, activeFilter} = useContext(CampaignContext)
  const [userAgreement, setUserAgreement] = useState(false)
  const [autoAccept, setAutoAccept] = useState(false)
  const [showAutoAccept, setShowAutoAccept] = useState(false)
  const navigate = useNavigate()
  const {t} = useTranslation()

  useEffect(() => {
    if (campaign?.streamer_status === 'accepted') {
      setUserAgreement(true)
    }
  }, [campaign])

  useEffect(() => {
    if (!campaign || !autoAcceptSettings) {
      return
    }
    if (autoAcceptSettings.autoAcceptPreference === EnumStreamerAutoAcceptPreference.ALL) {
      setShowAutoAccept(false)
    }
    if (autoAcceptSettings.autoAcceptPreference === EnumStreamerAutoAcceptPreference.MANUAL) {
      setShowAutoAccept(true)
    }
    if (autoAcceptSettings.autoAcceptPreference === EnumStreamerAutoAcceptPreference.BRAND) {
      if (autoAcceptSettings.autoAcceptClients.map((x) => x.id).includes(campaign.client_id)) {
        setShowAutoAccept(false)
      } else {
        setShowAutoAccept(true)
      }
    }
  }, [campaign, autoAcceptSettings])

  const fullUrl = window.location
  const originUrl = fullUrl.pathname.split('/')[1]

  // ACCEPT AND SEND IT TO /CAMPAIGNS/ID
  const acceptCampaign = async () => {
    if (!campaign) {
      return
    }
    try {
      await toast.promise(
        campaignService.updateCampaignStatus({
          campaignId: campaign.campaign_id,
          status: EnumCampaignHasStreamerStatus.Accepted,
          autoAcceptClient: autoAccept,
        }),
        {
          pending: 'Accepting campaign...',
          success: 'Campaign accepted!',
          error: 'Error accepting campaign',
        }
      )
      if (originUrl !== 'campaigns') {
        navigate(`/${originUrl}`)
      } else {
        navigate(`/campaigns`)
      }
      setActiveFilter('active')
    } catch (error) {
      console.log(error)
    }
  }

  // DECLINE AND SEND IT TO /CAMPAIGNS/ID
  const declineCampaign = async () => {
    if (!campaign) {
      return
    }
    try {
      await campaignService.updateCampaignStatus({
        campaignId: campaign.campaign_id,
        status: EnumCampaignHasStreamerStatus.Denied,
        autoAcceptClient: null,
      })
      setActiveFilter('denied')
      if (originUrl !== 'campaigns') {
        navigate(`/${originUrl}`)
      } else {
        navigate(`/campaigns`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (!campaign || !autoAcceptSettings) {
    return null
  }
  return (
    <div className={styles.campaignPopupTab}>
      <div className={styles.campaignCustomer}>
        <div className={styles.brandName}>{campaign?.client_name}</div>
        <div className={styles.campaignName}>{campaign?.campaign_name}</div>
      </div>
      <div className={styles.campaignDetail_header}>
        <div className={styles.detailHeader_item}>
          <div className={classNames(styles.item_title, styles.startEndDate)}>
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/start-date-icon.png')} alt='icon' />
            {t('CampaignsPopup.startDate')}
          </div>
          <div className={styles.item_value}>
            {formatDate('MMM DD, YYYY', campaign?.start_date)}
          </div>
        </div>
        <div className={styles.detailHeader_item}>
          <div className={classNames(styles.item_title, styles.startEndDate)}>
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/start-date-icon.png')} alt='icon' />
            {t('CampaignsPopup.endDate')}
          </div>
          <div className={styles.item_value}>{formatDate('MMM DD, YYYY', campaign?.end_date)}</div>
        </div>
        <div className={styles.detailHeader_item}>
          <div className={classNames(styles.item_title, styles.payableDate)}>
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/payable-date-icon.png')} alt='icon' />
            {t('CampaignsPopup.payableDate')}
          </div>
          <div className={styles.item_value}>
            {formatDate('MMM DD, YYYY', campaign?.payable_date)}
          </div>
        </div>
        {!campaign?.is_featured && (
          <div className={styles.detailHeader_item}>
            <div className={classNames(styles.item_title, styles.payableDate, styles.tooltip)}>
              <img src={toAbsoluteUrl('/assets/icons/Campaigns/rpm-icon.png')} alt='icon' />
              {t('CampaignsPopup.rpm')}
              <div className={styles.tooltipText}>{t('CampaignsPopup.rpmExplanation')}</div>
            </div>
            <div className={styles.item_value}>
              {campaign?.CPM}
              {user?.currency_sign}
            </div>
          </div>
        )}
      </div>

      <TargetingInfo campaign={campaign} />

      <div className={styles.participations}>
        {streamerList.streamers?.map((streamer, index) => (
          <img
            draggable={false}
            onContextMenu={(e) => e.preventDefault()}
            src={
              streamer.link_to_logo ??
              toAbsoluteUrl(`/assets/icons/Leaderboard/defaultProfileImg.png`)
            }
            alt={streamer.title}
            title={streamer.title}
            key={index}
            onError={(e) => {
              e.currentTarget.src = toAbsoluteUrl('/assets/icons/Leaderboard/defaultProfileImg.png')
            }}
          />
        ))}
        <span className={styles.participationsDetail}>
          <Trans
            i18nKey={'CampaignsPopup.streamersInCampaign'}
            values={{
              count: '+' + streamerList.count,
            }}
          >
            <span className={styles.participationsCount}></span>
          </Trans>
        </span>
      </div>

      {campaign?.campaign_type === 'poll' || campaign.campaign_type === 'interactive' ? (
        <PollDemo campaign={campaign} />
      ) : (
        <CampaignDemo campaign={campaign} />
      )}

      <div className={styles.campaignDetail_footer}>
        <div className={styles.campaignBrief}>
          <img
            className={styles.livadLogoMark}
            src={toAbsoluteUrl('/assets/icons/logoMark.png')}
            alt='logo'
          />
          <div className={styles.campaignBrief_title}>{t('CampaignsPopup.campaignBrief')}</div>
          {campaign?.campaign_brief ? (
            <p
              className={styles.campaignBrief_text}
              dangerouslySetInnerHTML={{__html: campaign?.campaign_brief}}
            ></p>
          ) : (
            <p>{campaign.pretext}</p>
          )}
        </div>
        {campaign?.campaign_status !== 'past' && (
          <div className={styles.campaignAgreement}>
            {!showAutoAccept ? null : (
              <div className={styles.campaignAgreement_title}>
                <div
                  className={classNames(styles.checkbox, {
                    [styles.checked]: autoAccept,
                  })}
                  onClick={() => {
                    setAutoAccept(!autoAccept)
                  }}
                >
                  <img
                    src={toAbsoluteUrl('/assets/icons/Campaigns/check-icon.png')}
                    alt='checked'
                  />
                </div>
                <span className={styles.agreementText}>
                  {t('CampaignsPopup.autoAcceptCampaign', {
                    brand: campaign?.client_name,
                  })}
                </span>
              </div>
            )}
            <div className={styles.campaignAgreement_title}>
              <div
                className={classNames(styles.checkbox, {
                  [styles.checked]: userAgreement,
                })}
                onClick={() => {
                  setUserAgreement(!userAgreement)
                }}
              >
                <img src={toAbsoluteUrl('/assets/icons/Campaigns/check-icon.png')} alt='checked' />
              </div>
              <span className={styles.agreementText}>
                {t('CampaignsPopup.readAndUnderstoodCampaignBrief')}
              </span>
            </div>

            <div className={styles.campaignButtons}>
              <div
                className={classNames(styles.denyButton, {
                  [styles.fullView]: campaign?.streamer_status === 'accepted',
                })}
                onClick={() => {
                  declineCampaign()
                }}
              >
                <div className={styles.buttonInner}>
                  <img src={toAbsoluteUrl('/assets/icons/Campaigns/close-icon.png')} alt='deny' />
                  {t('CampaignsPopup.deny')}
                </div>
              </div>
              {campaign?.streamer_status !== 'accepted' && (
                <div
                  className={classNames(styles.acceptButton, {[styles.available]: userAgreement})}
                  onClick={() => {
                    if (userAgreement) {
                      acceptCampaign()
                    } else {
                      toast.error('Please read and accept the campaign brief', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                      })
                    }
                  }}
                >
                  <div className={styles.buttonInner}>
                    <img
                      src={toAbsoluteUrl('/assets/icons/Campaigns/check-icon.png')}
                      alt='accept'
                    />
                    {t('CampaignsPopup.accept')}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CampaignDetail
